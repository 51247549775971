import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import styles from "./PassportRequirementReviewerView.module.css";

import { AddButton, CheckboxItem } from "@cpnw/ui";
import FormDatePicker from "../FormDatePicker";
import PassportDownloadItem from "../PassportDownloadItem";
import DashboardPassportFileEditorItem from "../DashboardPassportFileEditorItem";
import DashboardRadio from "../DashboardRadio";
import PassportMessageForm from "../PassportMessageForm";
import PassportReviewItem from "../PassportReviewItem";

import { Button, InputBox, Loading, SelectBox } from "@cpnw/ui";
import { RequirementContainer } from "hooks/useRequirement";
import { format } from "date-fns";
import QuestionToggle from "components/QuestionToggle";
import classNames from "classnames";
import { formatUTCMidnightToSameDay } from "utils/helpers";
import { DeclinationEnum } from "components/PassportUserInputView/PassportUserInputView";
import InsuranceView from "components/RequirementViews/InsuranceView";
import ScreenRespiratorView from "components/RequirementViews/ScreenRespiratorView";
import ImmunizationCovidView from "components/RequirementViews/ImmunizationCovidView";
import ImmunizationTdapView from "components/RequirementViews/ImmunizationTdapView";
import ImmunizationVaricellaView from "components/RequirementViews/ImmunizationVaricellaView";
import ImmunizationMMRView from "components/RequirementViews/ImmunizationMMRView";
import ImmunizationHepBView from "components/RequirementViews/ImmunizationHepBView";
import ImmunizationTBView from "components/RequirementViews/ImmunizationTBView";

const states = [
  "AL",
  "AK",
  "AS",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FM",
  "FL",
  "GA",
  "GU",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MH",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "MP",
  "OH",
  "OK",
  "OR",
  "PW",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VI",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

export default function PassportRequirementReviewerView({
  requirement,
  onSaveComplete,
}) {
  const { useRequirementMessages, postRequirementMessage, postApproval } =
    RequirementContainer.useContainer();

  const { data: messagesData, refetch: refetchMessages } =
    useRequirementMessages(requirement.contactRequirementId);
  const messages = (
    (messagesData?.length > requirement.messages?.length
      ? messagesData
      : requirement.messages) || []
  ).map((a) => {
    return {
      name: a.FromContact,
      date: format(a.RepliedOn, "Pp"),
      message: a.Message,
    };
  });

  const canReview = requirement.canReview;
  const disabled = true; //Reviewer cannot make lots of changes
  const canSave = true && canReview; //TODO: Education can override CPNW
  const expirationDateRequired = requirement.frequency != "Once";

  const [errors, setErrors] = useState([]);

  const handleDeclinationChange = (value) => {
    setDeclination(value);
  };

  const handleOnSend = useCallback(
    (value) => {
      console.log("requirement", requirement);
      postRequirementMessage({
        contactRequirementId: requirement.contactRequirementId,
        message: value,
      }).then(() => {
        refetchMessages();
      });
    },
    [requirement]
  );

  const [declination, setDeclination] = useState(
    requirement.submission.Declination || null
  );
  const [immunizationDate, setImmunizationDate] = useState(
    requirement.submission.ImmunizationDate
      ? formatUTCMidnightToSameDay(
        requirement.submission.ImmunizationDate,
        "MM/dd/yyyy"
      )
      : ""
  );
  const [expirationDate, setExpirationDate] = useState(
    requirement.submission.ExpiresOn
      ? formatUTCMidnightToSameDay(
        requirement.submission.ExpiresOn,
        "MM/dd/yyyy"
      )
      : ""
  );
  const [requirementExpirationDate, setRequirementExpirationDate] = useState(
    requirement.expiresOn
      ? formatUTCMidnightToSameDay(requirement.expiresOn, "MM/dd/yyyy")
      : ""
  );

  const [isWaived, setIsWaived] = useState(requirement.isWaived);

  const [rejectionMessage, setRejectionMessage] = useState("");
  const rejectionMessageRef = useRef(null);

  const [location, setLocation] = useState(
    requirement.submission.Location || ""
  );
  const [files, setFiles] = useState(requirement.submission.files || []);
  const [newFiles, setNewFiles] = useState([]);

  const [hasLicenses, setHasLicenses] = useState(
    requirement.hasLicenses ? "yes" : "no"
  );
  const [licenses, setLicenses] = useState(
    requirement.hasLicenses
      ? (requirement.submissions || []).map((license) => {
        return {
          id: license.Id,
          licenseType: license.LicenseType,
          licenseNumber: license.LicenseNumber,
          issuedBy: license.IssuedBy,
          issuedOn: license.IssuedOn
            ? formatUTCMidnightToSameDay(license.IssuedOn)
            : "",
          expiresOn: license.ExpiresOn
            ? formatUTCMidnightToSameDay(license.ExpiresOn)
            : "",
          files: (license.Files || []).map((a) => ({
            id: a.Id,
            name: a.Name,
            url: a.Url,
          })),
          newFiles: [],
        };
      })
      : []
  );

  const [viewResult, setViewResult] = useState(
    requirement.submission?.JSONContent
      ? JSON.parse(requirement.submission?.JSONContent)
      : {
        type: "",
        test1: {},
        test2: {},
        serie1: {},
        serie2: {},
      }
  );

  console.log("licenses", licenses);

  const handleChange = useCallback(
    (license, value, update) => {
      let name = update;

      const li = licenses.find((a) => a.id == license.id);
      li[name] = value;
      setLicenses([...licenses]);
    },
    [licenses]
  );

  const onAddMoreLicense = useCallback(() => {
    licenses.push({
      id: Math.random(),
      newFiles: [],
    });
    setLicenses([...licenses]);
  }, [licenses]);

  const licenseIndexForFilesRef = useRef(null);

  const onUploadLicenses = (index) => {
    licenseIndexForFilesRef.current = index;
    licenseFileRef.current.click();
  };

  const onLicenseFileChange = (e) => {
    const index = licenseIndexForFilesRef.current;
    const license = licenses[index];
    license.newFiles = e.target.files;
    setLicenses([...licenses]);
  };

  const onFileChange = (e) => {
    const newFiles = e.target.files;
    for (const f of newFiles) {
      f.id = Math.random();
    }
    setNewFiles([...newFiles]);
  };

  function removeFileFromFileList(fileList, indexToRemove) {
    const dt = new DataTransfer();

    for (let i = 0; i < fileList.length; i++) {
      if (i !== indexToRemove) {
        dt.items.add(fileList[i]);
      }
    }

    return dt.files;
  }

  const licenseFileRef = useRef(null);
  const fileRef = useRef(null);

  const onSetHasLicenses = useCallback(
    (value) => {
      if (value == "yes" && licenses.length == 0) {
        licenses.push({
          id: Math.random(),
          newFiles: [],
        });
        setLicenses([...licenses]);
      }

      setHasLicenses(value);
    },
    [hasLicenses, licenses]
  );

  const [approval, setApproval] = useState(requirement.status);

  useEffect(() => {
    setErrors([]);
  }, [approval]);

  const handleApprovalChange = (value) => {
    setApproval(value);
  };

  const [isLoading, setIsLoading] = useState(false);

  const onSave = () => {
    if (!canReview) {
      setErrors(["You cannot review this requirement"]);
      return;
    }
    if (approval == "Approved") {
      if (expirationDateRequired && !requirementExpirationDate) {
        setErrors(["Must enter Requirement Expiration Date"]);
        return;
      }
    } else if (approval == "Rejected") {
      if (!rejectionMessage) {
        rejectionMessageRef.current.focus();
        setErrors(["Must enter Rejection Message when selecting Reject"]);
        return;
      }
    } else {
      setErrors(["Please select either Accept or Reject"]);
      return;
    }

    const doesHaveLicenses = hasLicenses == "yes";
    const dto = {
      contactId: requirement.contactId,
      contactRequirementId: requirement.contactRequirementId,
      submissionId: requirement.submission.Id,
      licenses: doesHaveLicenses
        ? licenses.map((license) => {
          return {
            ...license,
            newFiles: Array.from(license.newFiles).map((f) => f.name),
          };
        })
        : [],
      declination: declination,
      immunizationDate,
      expirationDate,
      location,
      hasLicenses: doesHaveLicenses,
      files,
      newFiles: newFiles.map((a) => a.name),
      approval,
      rejectionMessage,
      viewResult: JSON.stringify(viewResult),
      isWaived
    };

    if (approval == "Approved") {
      if (expirationDateRequired) {
        dto.requirementExpirationDate = requirementExpirationDate;
      }
    }

    setErrors([]);
    console.log("PassportReqiurementReviewerView requirement", requirement);
    console.log("PassportReqiurementReviewerView OnSave dto", dto);

    setIsLoading(true);

    postApproval(dto).then(() => {
      onSaveComplete();
      setIsLoading(false);
    });
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.column}>
          <div className={styles.row}>
            <PassportReviewItem
              label="Requirement Name"
              description={requirement.requirementName}
            />
            <PassportReviewItem
              label="Abbr"
              description={requirement.abbreviation}
            />
            <PassportReviewItem
              label="Required By"
              description={requirement.requiredBy}
            />
            <PassportReviewItem
              label="Frequency"
              description={requirement.frequency}
            />
          </div>
          {/* <div className={styles.instructions}>
            <PassportReviewItem
              label="Instructions"
              description={requirement.instructions}
            />
          </div> */}
          {/* <div className={styles.row}>
            <div className={styles.column}>
              <p className={styles.paragraph}>Files/Forms</p>
              <div className={styles.fileSetup}>
                {requirement.requirementFiles.map((f) => {
                  return <PassportDownloadItem file={f} />;
                })}
              </div>
            </div>
          </div> */}
          <hr>{/* Horizontal Rule */}</hr>
          <h4>Submission:</h4>
          {requirement.allowWaiver && (
            <div className={styles.column}>
              <CheckboxItem
                className={styles.checkbox}
                onClick={(isChecked) => setIsWaived(!!isChecked)}
                description={"Waived"}
                isChecked={isWaived}
              />
            </div>
          )}
          {!isWaived && (
            <div>
              {requirement.subCategory &&
                requirement.subCategory.Name == "TB" && (
                  <ImmunizationTBView
                    data={viewResult}
                    isReviewing={true}
                    onChange={(result) => {
                      console.log("ImmunizationTBView result", result);
                      setViewResult(result);
                    }}
                  />
                )}
              {requirement.subCategory &&
                requirement.subCategory.Name == "HepB" && (
                  <ImmunizationHepBView
                    data={viewResult}
                    isReviewing={true}
                    onChange={(result) => {
                      console.log("ImmunizationHepBView result", result);
                      setViewResult(result);
                    }}
                  />
                )}
              {requirement.subCategory &&
                requirement.subCategory.Name == "MMR/MMRV" && (
                  <ImmunizationMMRView
                    data={viewResult}
                    isReviewing={true}
                    onChange={(result) => {
                      console.log("ImmunizationMMRView result", result);
                      setViewResult(result);
                    }}
                  />
                )}
              {requirement.subCategory &&
                requirement.subCategory.Name == "Varicella" && (
                  <ImmunizationVaricellaView
                    data={viewResult}
                    isReviewing={true}
                    onChange={(result) => {
                      console.log("ImmunizationVaricellaView result", result);
                      setViewResult(result);
                    }}
                  />
                )}
              {requirement.subCategory &&
                requirement.subCategory.Name == "Tdap" && (
                  <ImmunizationTdapView
                    data={viewResult}
                    isReviewing={true}
                    onChange={(result) => {
                      console.log("ImmunizationTdapView result", result);
                      setViewResult(result);
                    }}
                  />
                )}
              {requirement.subCategory &&
                requirement.subCategory.Name == "Covid-19" && (
                  <ImmunizationCovidView
                    data={viewResult}
                    isReviewing={true}
                    onChange={(result) => {
                      console.log("ImmunizationCovidView result", result);
                      setViewResult(result);
                    }}
                  />
                )}
              {requirement.subCategory &&
                requirement.subCategory.Name == "Respirator" && (
                  <ScreenRespiratorView
                    data={viewResult}
                    isReviewing={true}
                    onChange={(result) => {
                      console.log("ImmunizationRespiratorView result", result);
                      setViewResult(result);
                    }}
                  />
                )}
              {requirement.type.Name == "Insurance" && (
                <InsuranceView
                  data={viewResult}
                  isReviewing={true}
                  onChange={(result) => {
                    console.log("InsuranceView result", result);
                    setViewResult(result);
                  }}
                />
              )}
              {(requirement.type.HasImmunizationDate ||
                requirement.type.HasLocation || 
                requirement.type.HasExpiration) && (
                  <div className={styles.row}>
                    {requirement.type.HasImmunizationDate && (
                      <FormDatePicker
                        label="Immunization Date"
                        dateFormat="MM/dd/yyyy"
                        dateString={immunizationDate}
                        setDate={setImmunizationDate}
                        disabled={!canReview}
                      />
                    )}
                    {requirement.type.HasExpiration && (
                      <FormDatePicker
                        label="Expiration Date"
                        dateFormat="MM/dd/yyyy"
                        dateString={expirationDate}
                        setDate={setExpirationDate}
                        disabled={!canReview}
                      />
                    )}
                    {requirement.type.HasLocation && (
                      <div className={styles.column}>
                        <InputBox
                          label="Location Received"
                          placeholder="Provider Name & Location"
                          value={location}
                          setValue={(e) => {
                            setLocation(e.target.value);
                          }}
                          disabled={!canReview}
                        />
                      </div>
                    )}
                  </div>
                )}
              {requirement.type.HasDeclination && (
                <div className={styles.column}>
                  <p className={styles.paragraph}>Declination Reason</p>
                  <div className={styles.row}>
                    <DashboardRadio
                      isSelected={declination === DeclinationEnum.EggAllergy}
                      onChange={handleDeclinationChange}
                      value={DeclinationEnum.EggAllergy}
                      label={DeclinationEnum.EggAllergy}
                      disabled={!canReview}
                    />
                    <DashboardRadio
                      isSelected={
                        declination === DeclinationEnum.IngredientAllergy
                      }
                      onChange={handleDeclinationChange}
                      value={DeclinationEnum.IngredientAllergy}
                      label={DeclinationEnum.IngredientAllergy}
                      disabled={!canReview}
                    />
                    <DashboardRadio
                      isSelected={declination === DeclinationEnum.GuillainBarre}
                      onChange={handleDeclinationChange}
                      value={DeclinationEnum.GuillainBarre}
                      label={DeclinationEnum.GuillainBarre}
                      disabled={!canReview}
                    />
                    <DashboardRadio
                      isSelected={declination === DeclinationEnum.Other}
                      onChange={handleDeclinationChange}
                      value={DeclinationEnum.Other}
                      label={DeclinationEnum.Other}
                      disabled={!canReview}
                    />
                  </div>
                </div>
              )}

              {requirement.type.HasLicense ? (
                <>
                  <div className={styles.innerColumnContainer}>
                    <div className={styles.innerColumn}>
                      <span className={styles.textBold}>
                        Do you hold an active healthcare license?
                      </span>
                      <QuestionToggle
                        disabled={disabled || !canReview}
                        value={hasLicenses}
                        onClick={(value) => onSetHasLicenses(value)}
                      />
                    </div>
                  </div>
                  <div>
                    {licenses.length > 0 && (
                      <div className={styles.licenseContainer}>
                        <div className={styles.innerColumnContainer}>
                          <div className={styles.innerColumn}>
                            <span className={styles.textBold}>
                              Please enter your license information and attach
                              copies.
                              <br />
                            </span>
                          </div>
                        </div>
                      </div>
                    )}

                    {licenses.map((license, index) => {
                      return (
                        <div className={styles.licenseContainer}>
                          <div
                            className={classNames(styles.innerColumnContainer)}
                          >
                            <div className={classNames(styles.innerColumn)}>
                              <InputBox
                                label={"License Type"}
                                placeholder={"License Type"}
                                setValue={(e) =>
                                  handleChange(
                                    license,
                                    e.target.value,
                                    "licenseType"
                                  )
                                }
                                value={license.licenseType}
                                disabled={!canReview}
                              />
                            </div>
                            <div className={classNames(styles.innerColumn)}>
                              <SelectBox
                                label={"State"}
                                onChange={(value) => {
                                  handleChange(license, value, "issuedBy");
                                }}
                                placeholder={"Select"}
                                options={states}
                                selected={license.issuedBy}
                                disabled={disabled}
                              />
                              {/* <InputBox
                            label={"Issued By"}
                            placeholder={"Issued By"}
                            setValue={(e) =>
                              handleChange(license, e.target.value, "issuedBy")
                            }
                            value={license.issuedBy}
                            disabled={!canReview}
                          /> */}
                            </div>
                            <div className={classNames(styles.innerColumn)}>
                              <InputBox
                                label={"License #"}
                                placeholder={"License #"}
                                setValue={(e) =>
                                  handleChange(
                                    license,
                                    e.target.value,
                                    "licenseNumber"
                                  )
                                }
                                value={license.licenseNumber}
                                disabled={!canReview}
                              />
                            </div>
                            {/* <div className={classNames(styles.innerColumn)}>
                              <FormDatePicker
                                label={"Issued On"}
                                placeholder={"Issued On"}
                                setDate={(value) => {
                                  handleChange(license, value, "issuedOn");
                                }}
                                dateString={license.issuedOn}
                                dateFormat="MM/dd/yyyy"
                                disabled={!canReview}
                              />
                            </div> */}
                            <div className={classNames(styles.innerColumn)}>
                              <FormDatePicker
                                label={"Expiration Date"}
                                placeholder={"Expiration Date"}
                                setDate={(value) => {
                                  handleChange(license, value, "expiresOn");
                                }}
                                dateString={license.expiresOn}
                                dateFormat="MM/dd/yyyy"
                                disabled={!canReview}
                              />
                            </div>
                          </div>
                          <div className={styles.innerColumnContainer}>
                            <div className={styles.innerColumn}>
                              <div className={styles.upload}>
                                {!disabled && (
                                  <AddButton
                                    onClick={() => onUploadLicenses(index)}
                                    label={"Add Files"}
                                    className={classNames(
                                      styles.addFileIcon,
                                      styles.actionIcons
                                    )}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                          <div
                            className={classNames(styles.innerColumnContainer)}
                          >
                            <div
                              className={classNames(
                                styles.innerColumn,
                                styles.actions
                              )}
                            >
                              {license.files && license.files.length > 0
                                ? license.files.map((f, fi) => (
                                  <DashboardPassportFileEditorItem
                                    name={f.name}
                                    onView={() => {
                                      window.open(f.url, "_blank");
                                    }}
                                    onDelete={() => {
                                      license.files = license.files.filter(
                                        (a) => a.Id != f.Id
                                      );
                                      setLicenses([...licenses]);
                                    }}
                                    canDelete={!disabled}
                                  />
                                ))
                                : null}
                              {license.newFiles && license.newFiles.length > 0
                                ? Array.from(license.newFiles).map((f, fi) => (
                                  <DashboardPassportFileEditorItem
                                    name={f.name}
                                    onView={() => {
                                      window.open(
                                        URL.createObjectURL(f),
                                        "_blank"
                                      );
                                    }}
                                    onDelete={() => {
                                      license.newFiles =
                                        removeFileFromFileList(
                                          license.newFiles,
                                          fi
                                        );
                                      setLicenses([...licenses]);
                                    }}
                                    canDelete={!disabled}
                                  />
                                ))
                                : null}
                            </div>
                          </div>
                          <hr />
                        </div>
                      );
                    })}

                    <input
                      type="file"
                      multiple={true}
                      onChange={(e) => onLicenseFileChange(e)}
                      ref={licenseFileRef}
                      style={{ display: "none" }}
                    />

                    {licenses.length > 0 && !disabled ? (
                      <AddButton
                        onClick={onAddMoreLicense}
                        label={"Add Another License"}
                        className={classNames(
                          styles.addFileIcon,
                          styles.actionIcons
                        )}
                      />
                    ) : null}
                  </div>
                </>
              ) : null}
            </div>
          )}
          {requirement.type.HasDocumentation && (
            <>
              <div className={styles.uploadRow}>
                <p className={styles.paragraph}>Upload Documentation</p>
                {!disabled && (
                  <AddButton
                    label="Add File"
                    onClick={() => {
                      fileRef.current.click();
                    }}
                  />
                )}
                <input
                  type="file"
                  multiple={true}
                  onChange={(e) => onFileChange(e)}
                  ref={fileRef}
                  style={{ display: "none" }}
                />
              </div>
              <div className={styles.fileSetup}>
                {files.map((f) => (
                  <DashboardPassportFileEditorItem
                    name={f.name}
                    onView={() => {
                      console.log("onView ", f);
                      window.open(f.url, "_blank");
                    }}
                    onDelete={() => {
                      console.log("onDelete ", f);
                      setFiles((files) =>
                        files.filter((file) => file.id != f.id)
                      );
                    }}
                    canDelete={!disabled}
                  />
                ))}
                {Array.from(newFiles).map((f) => (
                  <DashboardPassportFileEditorItem
                    name={f.name}
                    onView={() => {
                      console.log("onView ", f);
                      window.open(URL.createObjectURL(f), "_blank");
                    }}
                    onDelete={() => {
                      console.log("onDelete ", f);
                      setNewFiles((files) =>
                        files.filter((file) => file.id != f.id)
                      );
                    }}
                    canDelete={!disabled}
                  />
                ))}
              </div>
            </>
          )}
          <div className={styles.row}>
            <div className={styles.column}>
              <p className={styles.paragraph}>Approval</p>
              <div className={styles.row}>
                <DashboardRadio
                  isSelected={approval === "Approved"}
                  onChange={handleApprovalChange}
                  value="Approved"
                  label="Accept"
                  disabled={!canReview}
                />
                <DashboardRadio
                  isSelected={approval === "Rejected"}
                  onChange={handleApprovalChange}
                  value="Rejected"
                  label="Reject"
                  disabled={!canReview}
                />
              </div>
              <div className={styles.row}>
                {approval == "Approved" && expirationDateRequired && (
                  //Expiration Date
                  <FormDatePicker
                    label={"Requirement Expiration Date"}
                    placeholder={"MM/DD/YYYY"}
                    setDate={(value) => {
                      setRequirementExpirationDate(value);
                    }}
                    dateString={requirementExpirationDate}
                    dateFormat="MM/dd/yyyy"
                    disabled={!canReview}
                  />
                )}
                {approval == "Rejected" && (
                  <>
                    <label htmlFor="messageContent">Why is it rejected?</label>
                    <textarea
                      name="messageContent"
                      id="messageContent"
                      ref={rejectionMessageRef}
                      className={styles.text_area}
                      onChange={(e) => setRejectionMessage(e.target.value)}
                      disabled={!canReview}
                    ></textarea>
                  </>
                )}
              </div>
            </div>
          </div>
          {errors.length > 0 && (
            <ul>
              {errors.map((a) => {
                return <li className={styles.error}>{a}</li>;
              })}
            </ul>
          )}

          {canSave && (
            <Button
              className={styles.custom_button}
              onClick={onSave}
              text="SAVE"
            />
          )}

          <div className={styles.error}>
            {!canReview
              ? "You don't have permissions to review this requirement!"
              : null}
          </div>
        </div>

        <div className={styles.borderRight}></div>
        <div className={styles.column}>
          <h3>Submission Status:</h3>
          <h3>{requirement.status}</h3>

          <hr>{/* Horizontal Rule */}</hr>
          <PassportMessageForm messages={messages} onSend={handleOnSend} />
          <hr>{/* Horizontal Rule */}</hr>
        </div>

        {isLoading && (
          <div className={styles.loading}>
            <Loading />
          </div>
        )}
      </div>
    </>
  );
}

PassportRequirementReviewerView.propTypes = {};
