import React, { useCallback, useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./PassportUserInputView.module.css";

import { AddButton } from "@cpnw/ui";
import FormDatePicker from "../FormDatePicker";
import PassportDownloadItem from "../PassportDownloadItem";
import DashboardPassportFileEditorItem from "../DashboardPassportFileEditorItem";
import DashboardRadio from "../DashboardRadio";
import PassportMessageForm from "../PassportMessageForm";
import PassportReviewItem from "../PassportReviewItem";

import { Button, InputBox, Loading, SelectBox, CheckboxItem } from "@cpnw/ui";
import { RequirementContainer } from "hooks/useRequirement";
import { format } from "date-fns";
import QuestionToggle from "components/QuestionToggle";
import classNames from "classnames";
import { formatUTCMidnightToSameDay } from "utils/helpers";
import ImmunizationTBView from "components/RequirementViews/ImmunizationTBView";
import ImmunizationHepBView from "components/RequirementViews/ImmunizationHepBView";
import ImmunizationMMRView from "components/RequirementViews/ImmunizationMMRView";
import ImmunizationVaricellaView from "components/RequirementViews/ImmunizationVaricellaView";
import ImmunizationTdapView from "components/RequirementViews/ImmunizationTdapView";
import ImmunizationCovidView from "components/RequirementViews/ImmunizationCovidView";
import ImmunizationRespiratorView from "components/RequirementViews/ScreenRespiratorView";
import ImmunizationInsuranceView from "components/RequirementViews/InsuranceView";
import InsuranceView from "components/RequirementViews/InsuranceView";
import ScreenRespiratorView from "components/RequirementViews/ScreenRespiratorView";

export const DeclinationEnum = {
  EggAllergy: "Egg Allergy",
  IngredientAllergy: "Ingredient Allergy",
  GuillainBarre: "Guillain Barre",
  Other: "Other",
};

const states = [
  "AL",
  "AK",
  "AS",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FM",
  "FL",
  "GA",
  "GU",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MH",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "MP",
  "OH",
  "OK",
  "OR",
  "PW",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VI",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

export default function PassportUserInputView({ requirement, onSaveComplete }) {
  const [dataLoading, setDataLoading] = useState(false);
  const elementRef = useRef(null);
  const [size, setSize] = useState({ width: 0, height: 0 });

  console.log("PassportUserInputView", requirement);
  const { useRequirementMessages, postRequirementMessage, submitRequirement } =
    RequirementContainer.useContainer();

  const { data: messagesData, refetch: refetchMessages } =
    useRequirementMessages(requirement.contactRequirementId);
  const messages = (messagesData || requirement.messages || []).map((a) => {
    return {
      name: a.FromContact,
      date: format(a.RepliedOn, "Pp"),
      message: a.Message,
    };
  });

  const disabled =
    requirement.status == "Approved" || requirement.status == "In Review";

  const handleDeclinationChange = (value) => {
    setDeclination(value);
  };

  const handleOnSend = useCallback(
    (value) => {
      console.log("requirement", requirement);
      postRequirementMessage({
        contactRequirementId: requirement.contactRequirementId,
        message: value,
      }).then(() => {
        refetchMessages();
      });
    },
    [requirement]
  );

  const [isWaived, setIsWaived] = useState(requirement.isWaived);

  const [declination, setDeclination] = useState(
    requirement.submission.Declination || null
  );
  const [immunizationDate, setImmunizationDate] = useState(
    requirement.submission.ImmunizationDate
      ? formatUTCMidnightToSameDay(
          requirement.submission.ImmunizationDate,
          "MM/dd/yyyy"
        )
      : ""
  );
  const [expirationDate, setExpirationDate] = useState(
    requirement.submission.ExpiresOn
      ? formatUTCMidnightToSameDay(
          requirement.submission.ExpiresOn,
          "MM/dd/yyyy"
        )
      : ""
  );
  const [location, setLocation] = useState(
    requirement.submission.Location || ""
  );
  const [files, setFiles] = useState(requirement.submission.files || []);
  const [newFiles, setNewFiles] = useState([]);

  const [hasLicenses, setHasLicenses] = useState(
    requirement.hasLicenses ? "yes" : "no"
  );
  const [licenses, setLicenses] = useState(
    requirement.hasLicenses
      ? (requirement.submissions || []).map((license) => {
          return {
            id: license.Id,
            licenseType: license.LicenseType,
            licenseNumber: license.LicenseNumber,
            issuedBy: license.IssuedBy,
            issuedOn: license.IssuedOn
              ? formatUTCMidnightToSameDay(license.IssuedOn)
              : "",
            expiresOn: license.ExpiresOn
              ? formatUTCMidnightToSameDay(license.ExpiresOn)
              : "",
            files: (license.Files || []).map((a) => ({
              id: a.Id,
              name: a.Name,
              url: a.Url,
            })),
            newFiles: [],
          };
        })
      : []
  );

  console.log("licenses", licenses);

  const handleChange = useCallback(
    (license, value, update) => {
      let name = update;

      const li = licenses.find((a) => a.id == license.id);
      li[name] = value;
      setLicenses([...licenses]);
    },
    [licenses]
  );

  const onAddMoreLicense = useCallback(() => {
    licenses.push({
      id: Math.random(),
      newFiles: [],
    });
    setLicenses([...licenses]);
  }, [licenses]);

  const licenseIndexForFilesRef = useRef(null);

  const onUploadLicenses = (index) => {
    licenseIndexForFilesRef.current = index;
    licenseFileRef.current.click();
  };

  const onLicenseFileChange = (e) => {
    const index = licenseIndexForFilesRef.current;
    const license = licenses[index];
    license.newFiles = e.target.files;
    setLicenses([...licenses]);
  };

  const onFileChange = (e) => {
    const addFiles = e.target.files;
    for (const f of addFiles) {
      f.id = Math.random();
    }
    setNewFiles((newFiles) => [...newFiles, ...addFiles]);
  };

  function removeFileFromFileList(fileList, indexToRemove) {
    const dt = new DataTransfer();

    for (let i = 0; i < fileList.length; i++) {
      if (i !== indexToRemove) {
        dt.items.add(fileList[i]);
      }
    }

    return dt.files;
  }

  const licenseFileRef = useRef(null);
  const fileRef = useRef(null);

  const onSetHasLicenses = useCallback(
    (value) => {
      if (value == "yes" && licenses.length == 0) {
        licenses.push({
          id: Math.random(),
          newFiles: [],
        });
        setLicenses([...licenses]);
      }

      setHasLicenses(value);
    },
    [hasLicenses, licenses]
  );

  const [viewResult, setViewResult] = useState(
    requirement.submission?.JSONContent
      ? JSON.parse(requirement.submission?.JSONContent)
      : {
          type: "",
          test1: {},
          test2: {},
          serie1: {},
          serie2: {},
        }
  );

  const onSave = async () => {
    setDataLoading(true);
    try {
      const doesHaveLicenses = hasLicenses == "yes";
      const dto = {
        contactRequirementId: requirement.contactRequirementId,
        submissionId: requirement.submission.Id,
        licenses: doesHaveLicenses
          ? licenses.map((license) => {
              return {
                ...license,
                newFiles: Array.from(license.newFiles).map((f) => f.name),
              };
            })
          : [],
        declination: declination,
        immunizationDate,
        expirationDate,
        location,
        hasLicenses: doesHaveLicenses,
        files,
        newFiles: newFiles.map((a) => a.name),
        viewResult: JSON.stringify(viewResult),
        isWaived,
      };

      await submitRequirement(
        dto,
        [...newFiles].concat(
          licenses.flatMap((license) => {
            return Array.from(license.newFiles);
          })
        )
      ).then(() => {
        if (onSaveComplete) {
          onSaveComplete();
        }
      });

      console.log("UserInputView requirement", requirement);
      console.log("UserInputView OnSave dto", dto);
    } catch (error) {
      // Handle any errors here
      console.error("onSave failed:", error);
    } finally {
      // Set loading state to false, regardless of success or failure
      setDataLoading(false);
    }
  };

  useEffect(() => {
    if (elementRef.current) {
      const { width, height } = elementRef.current.getBoundingClientRect();
      setSize({ width, height });
    }
  }, []);

  return (
    <>
      <div className={styles.container} ref={elementRef}>
        {dataLoading && (
          <div
            className={styles.loadingContainer}
            style={{ height: size.height + "px" }}
          >
            <Loading />
          </div>
        )}
        <div className={styles.column}>
          <div className={styles.row}>
            <PassportReviewItem
              label="Requirement Name"
              description={requirement.requirementName}
            />
            <PassportReviewItem
              label="Abbr"
              description={requirement.abbreviation}
            />
            <PassportReviewItem
              label="Required By"
              description={requirement.requiredBy}
            />
            <PassportReviewItem
              label="Frequency"
              description={requirement.frequency}
            />
          </div>
          <div className={styles.instructions}>
            <PassportReviewItem
              label="Instructions"
              description={requirement.instructions}
            />
          </div>
          <div className={styles.row}>
            <div className={styles.column}>
              <p className={styles.paragraph}>Files/Forms</p>
              <div className={styles.fileSetup}>
                {requirement.requirementFiles.map((f) => {
                  return <PassportDownloadItem file={f} />;
                })}
              </div>
            </div>
          </div>
          <hr>{/* Horizontal Rule */}</hr>
          <h4>Submission:</h4>
          {requirement.allowWaiver && (
            <div className={styles.column}>
              <CheckboxItem
                className={styles.checkbox}
                onClick={(isChecked) => setIsWaived(!!isChecked)}
                description={"Waive this requirement"}
                isChecked={isWaived}
              />
            </div>
          )}
          {!isWaived && (
            <div>
              {requirement.subCategory &&
                requirement.subCategory.Name == "TB" && (
                  <ImmunizationTBView
                    data={viewResult}
                    onChange={(result) => {
                      console.log("ImmunizationTBView result", result);
                      setViewResult(result);
                    }}
                    disabled={disabled}
                  />
                )}
              {requirement.subCategory &&
                requirement.subCategory.Name == "HepB" && (
                  <ImmunizationHepBView
                    data={viewResult}
                    onChange={(result) => {
                      console.log("ImmunizationHepBView result", result);
                      setViewResult(result);
                    }}
                    disabled={disabled}
                  />
                )}
              {requirement.subCategory &&
                requirement.subCategory.Name == "MMR/MMRV" && (
                  <ImmunizationMMRView
                    data={viewResult}
                    onChange={(result) => {
                      console.log("ImmunizationMMRView result", result);
                      setViewResult(result);
                    }}
                    disabled={disabled}
                  />
                )}
              {requirement.subCategory &&
                requirement.subCategory.Name == "Varicella" && (
                  <ImmunizationVaricellaView
                    data={viewResult}
                    onChange={(result) => {
                      console.log("ImmunizationVaricellaView result", result);
                      setViewResult(result);
                    }}
                    disabled={disabled}
                  />
                )}
              {requirement.subCategory &&
                requirement.subCategory.Name == "Tdap" && (
                  <ImmunizationTdapView
                    data={viewResult}
                    onChange={(result) => {
                      console.log("ImmunizationTdapView result", result);
                      setViewResult(result);
                    }}
                    disabled={disabled}
                  />
                )}
              {requirement.subCategory &&
                requirement.subCategory.Name == "Covid-19" && (
                  <ImmunizationCovidView
                    data={viewResult}
                    onChange={(result) => {
                      console.log("ImmunizationCovidView result", result);
                      setViewResult(result);
                    }}
                    disabled={disabled}
                  />
                )}
              {requirement.subCategory &&
                requirement.subCategory.Name == "Respirator" && (
                  <ScreenRespiratorView
                    data={viewResult}
                    onChange={(result) => {
                      console.log("ImmunizationRespiratorView result", result);
                      setViewResult(result);
                    }}
                    disabled={disabled}
                  />
                )}
              {requirement.type.Name == "Insurance" && (
                <InsuranceView
                  data={viewResult}
                  onChange={(result) => {
                    console.log("InsuranceView result", result);
                    setViewResult(result);
                  }}
                  disabled={disabled}
                />
              )}
              {(requirement.type.HasImmunizationDate ||
                requirement.type.HasLocation ||
                requirement.type.HasExpiration) && (
                <div className={styles.row}>
                  {requirement.type.HasImmunizationDate && (
                    <FormDatePicker
                      label="Immunization Date"
                      dateFormat="MM/dd/yyyy"
                      dateString={immunizationDate}
                      setDate={setImmunizationDate}
                      disabled={disabled}
                    />
                  )}
                  {requirement.type.HasExpiration && (
                    <FormDatePicker
                      label="Expiration Date"
                      dateFormat="MM/dd/yyyy"
                      dateString={expirationDate}
                      setDate={setExpirationDate}
                      disabled={disabled}
                    />
                  )}
                  {requirement.type.HasLocation && (
                    <div className={styles.column}>
                      <InputBox
                        label="Location Received"
                        placeholder="Provider Name & Location"
                        value={location}
                        setValue={(e) => {
                          setLocation(e.target.value);
                        }}
                        disabled={disabled}
                      />
                    </div>
                  )}
                </div>
              )}
              {requirement.type.HasLicense ? (
                <>
                  <div className={styles.innerColumnContainer}>
                    <div className={styles.innerColumn}>
                      <span className={styles.textBold}>
                        Do you hold an active healthcare license?
                      </span>
                      <QuestionToggle
                        disabled={disabled}
                        value={hasLicenses}
                        onClick={(value) => onSetHasLicenses(value)}
                      />
                    </div>
                  </div>
                  <div>
                    {licenses.length > 0 && (
                      <div className={styles.licenseContainer}>
                        <div className={styles.innerColumnContainer}>
                          <div className={styles.innerColumn}>
                            <span className={styles.textBold}>
                              Please enter your license information and attach
                              copies.
                              <br />
                            </span>
                          </div>
                        </div>
                      </div>
                    )}

                    {licenses.map((license, index) => {
                      return (
                        <div className={styles.licenseContainer}>
                          <div
                            className={classNames(styles.innerColumnContainer)}
                          >
                            <div className={classNames(styles.innerColumn)}>
                              <InputBox
                                label={"License Type"}
                                placeholder={"License Type"}
                                setValue={(e) =>
                                  handleChange(
                                    license,
                                    e.target.value,
                                    "licenseType"
                                  )
                                }
                                value={license.licenseType}
                                disabled={disabled}
                              />
                            </div>
                            <div className={classNames(styles.innerColumn)}>
                              <SelectBox
                                label={"State"}
                                onChange={(value) => {
                                  handleChange(license, value, "issuedBy");
                                }}
                                placeholder={"Select"}
                                options={states}
                                selected={license.issuedBy}
                                disabled={disabled}
                              />
                              {/* <InputBox
                            label={"State"}
                            placeholder={"Issued By"}
                            setValue={(e) =>
                              handleChange(license, e.target.value, "issuedBy")
                            }
                            value={license.issuedBy}
                            disabled={disabled}
                          /> */}
                            </div>
                            <div className={classNames(styles.innerColumn)}>
                              <InputBox
                                label={"License #"}
                                placeholder={"License #"}
                                setValue={(e) =>
                                  handleChange(
                                    license,
                                    e.target.value,
                                    "licenseNumber"
                                  )
                                }
                                value={license.licenseNumber}
                                disabled={disabled}
                              />
                            </div>
                            {/* <div className={classNames(styles.innerColumn)}>
                                <FormDatePicker
                                  label={"Issued On"}
                                  placeholder={"Issued On"}
                                  setDate={(value) => {
                                    handleChange(license, value, "issuedOn");
                                  }}
                                  dateString={license.issuedOn}
                                  dateFormat="MM/dd/yyyy"
                                  disabled={disabled}
                                />
                              </div> */}
                            <div className={classNames(styles.innerColumn)}>
                              <FormDatePicker
                                label={"Expiration Date"}
                                placeholder={"Expiration Date"}
                                setDate={(value) => {
                                  handleChange(license, value, "expiresOn");
                                }}
                                dateString={license.expiresOn}
                                dateFormat="MM/dd/yyyy"
                                disabled={disabled}
                              />
                            </div>
                          </div>
                          <div className={styles.innerColumnContainer}>
                            <div className={styles.innerColumn}>
                              <div className={styles.upload}>
                                {!disabled && (
                                  <AddButton
                                    onClick={() => onUploadLicenses(index)}
                                    label={"Add Files"}
                                    className={classNames(
                                      styles.addFileIcon,
                                      styles.actionIcons
                                    )}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                          <div
                            className={classNames(styles.innerColumnContainer)}
                          >
                            <div
                              className={classNames(
                                styles.innerColumn,
                                styles.actions
                              )}
                            >
                              {license.files && license.files.length > 0
                                ? license.files.map((f, fi) => (
                                    <DashboardPassportFileEditorItem
                                      name={f.name}
                                      onView={() => {
                                        window.open(f.url, "_blank");
                                      }}
                                      onDelete={() => {
                                        license.files = license.files.filter(
                                          (a) => a.Id != f.Id
                                        );
                                        setLicenses([...licenses]);
                                      }}
                                      canDelete={!disabled}
                                    />
                                  ))
                                : null}
                              {license.newFiles && license.newFiles.length > 0
                                ? Array.from(license.newFiles).map((f, fi) => (
                                    <DashboardPassportFileEditorItem
                                      name={f.name}
                                      onView={() => {
                                        window.open(
                                          URL.createObjectURL(f),
                                          "_blank"
                                        );
                                      }}
                                      onDelete={() => {
                                        license.newFiles =
                                          removeFileFromFileList(
                                            license.newFiles,
                                            fi
                                          );
                                        setLicenses([...licenses]);
                                      }}
                                      canDelete={!disabled}
                                    />
                                  ))
                                : null}
                            </div>
                          </div>
                          <hr />
                        </div>
                      );
                    })}

                    <input
                      type="file"
                      multiple={true}
                      onChange={(e) => onLicenseFileChange(e)}
                      ref={licenseFileRef}
                      style={{ display: "none" }}
                    />

                    {licenses.length > 0 && !disabled ? (
                      <AddButton
                        onClick={onAddMoreLicense}
                        label={"Add Another License"}
                        className={classNames(
                          styles.addFileIcon,
                          styles.actionIcons
                        )}
                      />
                    ) : null}
                  </div>
                </>
              ) : null}

              {requirement.type.HasDeclination && (
                <div className={styles.column}>
                  <p className={styles.paragraph}>Declination Reason</p>
                  <div className={styles.row}>
                    <DashboardRadio
                      isSelected={declination === DeclinationEnum.EggAllergy}
                      onChange={handleDeclinationChange}
                      value={DeclinationEnum.EggAllergy}
                      label={DeclinationEnum.EggAllergy}
                      disabled={disabled}
                    />
                    <DashboardRadio
                      isSelected={
                        declination === DeclinationEnum.IngredientAllergy
                      }
                      onChange={handleDeclinationChange}
                      value={DeclinationEnum.IngredientAllergy}
                      label={DeclinationEnum.IngredientAllergy}
                      disabled={disabled}
                    />
                    <DashboardRadio
                      isSelected={declination === DeclinationEnum.GuillainBarre}
                      onChange={handleDeclinationChange}
                      value={DeclinationEnum.GuillainBarre}
                      label={DeclinationEnum.GuillainBarre}
                      disabled={disabled}
                    />
                    <DashboardRadio
                      isSelected={declination === DeclinationEnum.Other}
                      onChange={handleDeclinationChange}
                      value={DeclinationEnum.Other}
                      label={DeclinationEnum.Other}
                      disabled={disabled}
                    />
                  </div>
                </div>
              )}
            </div>
          )}

          {requirement.type.HasDocumentation && (
            <>
              <div className={styles.uploadRow}>
                <p className={styles.paragraph}>Upload Documentation</p>
                {!disabled && (
                  <AddButton
                    label="Add File"
                    onClick={() => {
                      fileRef.current.click();
                    }}
                  />
                )}
                <input
                  type="file"
                  multiple={true}
                  onChange={(e) => onFileChange(e)}
                  ref={fileRef}
                  style={{ display: "none" }}
                />
              </div>
              <div className={styles.fileSetup}>
                {files.map((f) => (
                  <DashboardPassportFileEditorItem
                    name={f.name}
                    onView={() => {
                      console.log("onView ", f);
                      window.open(f.url, "_blank");
                    }}
                    onDelete={() => {
                      console.log("onDelete ", f);
                      setFiles((files) =>
                        files.filter((file) => file.id != f.id)
                      );
                    }}
                    canDelete={!disabled}
                  />
                ))}
                {Array.from(newFiles).map((f) => (
                  <DashboardPassportFileEditorItem
                    name={f.name}
                    onView={() => {
                      console.log("onView ", f);
                      window.open(URL.createObjectURL(f), "_blank");
                    }}
                    onDelete={() => {
                      console.log("onDelete ", f);
                      setNewFiles((files) =>
                        files.filter((file) => file.id != f.id)
                      );
                    }}
                    canDelete={!disabled}
                  />
                ))}
              </div>
            </>
          )}

          {!disabled && (
            <Button
              className={styles.custom_button}
              onClick={onSave}
              text="SAVE"
            />
          )}
        </div>
        <div className={styles.borderRight}></div>
        <div className={styles.column}>
          <h3>Submission Status:</h3>
          <h3>{requirement.status}</h3>

          <hr>{/* Horizontal Rule */}</hr>
          <PassportMessageForm messages={messages} onSend={handleOnSend} />
          <hr>{/* Horizontal Rule */}</hr>
        </div>
      </div>
    </>
  );
}

PassportUserInputView.propTypes = {
  requirement: PropTypes.shape({
    requirementName: PropTypes.string,
    abbreviation: PropTypes.string,
    requiredBy: PropTypes.string,
    frequency: PropTypes.string,
    instructions: PropTypes.string,
    status: PropTypes.string,
    files: PropTypes.arrayOf({}),
    messages: PropTypes.arrayOf({}),
  }),
};
