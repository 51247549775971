import React, { useCallback, useMemo } from "react";
import { useState, useEffect } from "react";
import styles from "./ReviewPage.module.css";
import { CpnwContainer } from "hooks/useCpnw";
import { HeaderSection, LinkItem, Loading } from "@cpnw/ui";
import RequirementTable from "components/RequirementTable";
import { AdminContainer } from "hooks/useAdmin";
import { format, parseISO } from "date-fns";
import { StaticRoutes } from "pages/routes";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import PassportAdminReviewTable from "components/PassportAdminReviewTable";
import PassportRequirementSubmissions from "components/PassportRequirementSubmissions";
import { RequirementContainer } from "hooks/useRequirement";
import { AccountContainer } from "hooks/useAccount";

export default function ReviewPage() {
  const history = useHistory();
  const {
    contactsToReviewData, refetchContactsToReviewData,
    postVerificationComplete
  } = RequirementContainer.useContainer();

  const {userRoles} = AccountContainer.useContainer()

  // API Call on mount
  useEffect(async () => {
    setIsLoading(true)
    await refetchContactsToReviewData()
    setIsLoading(false)
  }, []);

  const data = useMemo(() => {
    if (
      contactsToReviewData && contactsToReviewData.data.length > 0
    ) {
      return contactsToReviewData.data.flatMap((a) => {
        return {
          id: a.ContactId,
          name: `${a.NameLast}, ${a.NameFirst}`,
          sid: a.IndividualId || "",
          school: a.Facility,
          program: a.Program,
          status: a.RegistrationStatus
        };
      });
    }
    return [];
  }, [
    contactsToReviewData
  ]);

  const [submissionModalOpen, setSubmissionModalOpen] = useState(false)
  const [submissionModalData, setSubmissionModalData] = useState(null)
  const [isLoading, setIsLoading] = useState(false);

  const roles = userRoles.map((a) => a.toUpperCase());

  const onItemView = useCallback((item) => {
    console.log("onItemView", item)
    const student = contactsToReviewData.data.find(a=>a.ContactId == item.id)

    const modalData = {
      contactId: student.ContactId,
      studentInfo: {
        lastName: student.NameLast,
        firstName: student.NameFirst,
        school: student.Facility,
        program: student.Program,
        sidEid: student.IndividualId || "",
        email: student.Email,
        phone: student.Phone,
        //emergency contact
        eContact: student.EmergencyContact,
        eContactPhone: student.EmergencyPhone
      },
      studentPhoto: student.PhotoUrl,
      assignmentsInfo: student.Assignments.flatMap(a=>({
        location: a.Facility,
        startDate: format(a.StartDate, "MM-dd-yyyy"),
        endDate: format(a.EndDate, "MM-dd-yyyy")
      })),
      recordInfo: []
    }

    setSubmissionModalData(modalData)
    setSubmissionModalOpen(true)
  }, [contactsToReviewData])

  return (
    <div className={styles.container}>
      {submissionModalOpen && (<PassportRequirementSubmissions 
        onModalClose={()=> {
          setSubmissionModalData(null)
          setSubmissionModalOpen(false)
        }}
        canCompleteVerification={roles.includes("CPNW-REVIEWER")}
        onCompleteVerification={()=>{
          postVerificationComplete(submissionModalData.contactId)
          .then(()=>{
            refetchContactsToReviewData()
          })
        }}
        assignmentsInfo={submissionModalData.assignmentsInfo}
        recordInfo={submissionModalData.recordInfo}
        studentInfo={submissionModalData.studentInfo}
        studentPhoto={submissionModalData.studentPhoto}
        contactId={submissionModalData.contactId}
      />)}
      <PassportAdminReviewTable 
        items={data}
        onItemView={onItemView}
      />
      {isLoading && (
        <div className={styles.loading}>
          <Loading />
        </div>
      )}
    </div>
  );
}
