import React, { useCallback, useMemo } from "react";
import { useState, useEffect } from "react";
import styles from "./RequirementFormPage.module.css";
import { CpnwContainer } from "hooks/useCpnw";
import { HeaderSection, LinkItem, Loading, Modal } from "@cpnw/ui";
import RequirementTable from "components/RequirementTable";
import { AdminContainer } from "hooks/useAdmin";
import { format, parseISO } from "date-fns";
import PassportCreateRequirements from "components/PassportCreateRequirements";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { StaticRoutes } from "pages/routes";
import { RequirementContainer } from "hooks/useRequirement";
import classNames from "classnames";
import { AccountContainer } from "hooks/useAccount";
import environment from "environment";

export default function RequirementFormPage() {
  const history = useHistory();
  const {
    requirementSelectboxValuesData,
    refetchRequirementSelectboxValues,
    abbreviationsData,
    refetchAbbreviations,
    requirement,
    setRequirement,
    createRequirement,
    updateRequirement,
    refetchAdminRequirements
  } = RequirementContainer.useContainer();

  const [rolesLoading, setRolesLoading] = useState(true);
  const accountContainerData = AccountContainer.useContainer();

  useEffect(() => {
    if (
      accountContainerData.userRoles &&
      accountContainerData.userRoles.length > 0
    ) {
      setRolesLoading(false);
    } else {
    }
  }, [accountContainerData.userRoles]);

  // Check if user has the required roles
  const hasRequiredRoles = useMemo(() => {
    if (!accountContainerData.userRoles) return false;
    return accountContainerData.userRoles.some((role) =>
      ["EDUCATION", "HEALTHCARE", "CPNW-ADMIN", "CPNW-REVIEWER"].includes(
        role.toUpperCase()
      )
    );
  }, [accountContainerData.userRoles]);

  useEffect(() => {
    if (!rolesLoading && !hasRequiredRoles) {
      window.location.href = environment.homeUrl;
      return;
    }
  }, [rolesLoading, hasRequiredRoles]);
  
  const isNew = requirement == null;
  const {meData, userRoles} = AccountContainer.useContainer()

  const showWaiver = userRoles.includes("HealthCare") || userRoles.includes("CPNW-Admin")
  const showWeeksBeforePlacement = userRoles.includes("HealthCare")
  const showBusinessUnitsInsteadOfFacilities = userRoles.includes("Education")

  // API Call on mount
  useEffect(async () => {
    setIsLoading(true);

    var requests = []
    if (
      !requirementSelectboxValuesData
    ){
      requests = [
        refetchRequirementSelectboxValues()
      ]
    }

    if(!abbreviationsData){
      requests.push(refetchAbbreviations())
    }

    await Promise.all(requests)
      
    setIsLoading(false);
  }, []);

  
  const selectFields = useMemo(() => {
    console.log("selectFields requirementSelectboxValuesData", requirementSelectboxValuesData)
    if (
      requirementSelectboxValuesData &&
      abbreviationsData
    ) {
      return {
        requiredBy: requirementSelectboxValuesData.Facilities.map((rf) => rf.Name),
        requiredByBusinessUnits: requirementSelectboxValuesData.BusinessUnits.map((rbu) => rbu.Name),
        category: requirementSelectboxValuesData.Categories.map((f) => f.Name),
        frequency: requirementSelectboxValuesData.Frequencies.map((f) => f.Name),
        appliesTo: requirementSelectboxValuesData.Disciplines.map((rf) => rf.Name),
        status: requirementSelectboxValuesData.Statuses.map((rf) => rf.Name),
        subCategories: requirementSelectboxValuesData.SubCategories,
        categories: requirementSelectboxValuesData.Categories,
        abbreviations: abbreviationsData.data,
        appliesToUserTypes: ["Student", "Faculty"]
      };
    }
    return {
      requiredBy: [],
      requiredByBusinessUnits: [],
      category: [],
      frequency: [],
      appliesTo: [],
      status: [],
      abbreviations: [],
      subCategories: [],
      categories: [],
      appliesToUserTypes: []
    };
  }, [
    abbreviationsData,
    requirementSelectboxValuesData
  ]);

  const selectedValues = useMemo(() => {
    if (
      requirement &&
      requirementSelectboxValuesData
    ) {
      return {
        requiredBy: requirementSelectboxValuesData.Facilities
          .filter((rf) => requirement.FacilityIds.includes(rf.Id))
          .map((rf) => rf.Name),
        requiredByBusinessUnits: requirementSelectboxValuesData.BusinessUnits
          .filter((rf) => requirement.BusinessUnitIds.includes(rf.Id))
          .map((rf) => rf.Name),
        category: requirementSelectboxValuesData.Categories.find(
          (rc) => rc.Id == requirement.CategoryId
        ).Name,
        frequency: requirementSelectboxValuesData.Frequencies.find((f) => f.Id == requirement.FrequencyId)
          .Name,
        appliesTo: requirementSelectboxValuesData.Disciplines
          .filter((rd) => requirement.DisciplineIds.includes(rd.Id))
          .map((rf) => rf.Name),
        status: requirementSelectboxValuesData.Statuses.find(
          (rsd) => rsd.Id == requirement.StatusId
        ).Name,
        subCategory: requirementSelectboxValuesData.SubCategories.find(
          (rsd) => rsd.Id == requirement.SubCategoryId
        )?.Name,
        title: requirement.Name,
        instructions: requirement.Instructions,
        abbr: requirement.Abbreviation,
        files: requirement.Files,
        module: requirement.Module
          ? {
              id: requirement.Module.Id,
              name: requirement.Module.Name,
              pathname: requirement.Module.Pathname,
              abbr: requirement.Module.Abbr,
            }
          : null,
        allowWaiver: requirement.AllowWaiver,
        weeksBeforePlacement: requirement.WeeksBeforePlacement,
        appliesToUserTypes: requirement.AppliesToUserTypes
      };
    }
    return {
      requiredBy: [],
      requiredByBusinessUnits: [],
      category: "",
      frequency: "",
      appliesTo: [],
      status: "",
      instructions: "",
      title: "",
      abbr: "",
      files: [],
      subCategory: ""
    };
  }, [
    requirement,
    requirementSelectboxValuesData
  ]);

  const [isLoading, setIsLoading] = useState(false);

  const onSave = useCallback(
    (values) => {
      console.log("onSave ", values);
      const dto = {
        name: values.title,
        instructions: values.instructions,
        abbreviation: values.abbr,
        categoryId: requirementSelectboxValuesData.Categories.find(
          (a) => a.Name == values.category
        ).Id,
        frequencyId: requirementSelectboxValuesData.Frequencies.find((a) => a.Name == values.frequency).Id,
        statusId: requirementSelectboxValuesData.Statuses.find((a) => a.Name == values.status)
          .Id,
        requiredByList: requirementSelectboxValuesData.Facilities
          .filter((a) => values.requiredBy.includes(a.Name))
          .map((a) => a.Id),
        requiredByBusinessUnits: requirementSelectboxValuesData.BusinessUnits
          .filter((a) => values.requiredByBusinessUnits.includes(a.Name))
          .map((a) => a.Id),
        appliesToList: requirementSelectboxValuesData.Disciplines
          .filter((a) => values.appliesTo.includes(a.Name))
          .map((a) => a.Id),
        subCategoryId: requirementSelectboxValuesData.SubCategories.find((a) => a.Name == values.subCategory)?.Id,
        forceRetake: values.forceRetake,
        allowWaiver: values.allowWaiver,
        weeksBeforePlacement: values.weeksBeforePlacement,
        appliesToUserTypes: values.appliesToUserTypes
      };

      if (values.newModule) {
        dto.moduleFileName = values.newModule.name;
      }

      setIsLoading(true);

      if (requirement) {
        //update
        dto.files = values.files;

        if (requirement.Module) {
          dto.module = requirement.Module;
        }

        dto.id = requirement.Id;

        updateRequirement(dto, values.newFiles || [], values.newModule)
          .then(() => {
            history.push(StaticRoutes.Requirements);
          })
          .catch((error) => {
            alert("Error:", error.toString());
          })
          .finally(async () => {
            await refetchAdminRequirements()
            setIsLoading(false);
          });
      } else {
        //create
        createRequirement(dto, values.newFiles || [], values.newModule)
          .then(() => {
            history.push(StaticRoutes.Requirements);
          })
          .catch((error) => {
            alert("Error:", error.toString());
          })
          .finally(async () => {
            await refetchAdminRequirements()
            setIsLoading(false);
          });
      }
      console.log("dto", dto);
    },
    [
      requirement,
      requirementSelectboxValuesData
    ]
  );

  console.log("RequirementFormPage selectFields", selectFields)

  return (
    <div className={styles.container}>
      <PassportCreateRequirements
        selectFields={selectFields}
        selectedValues={selectedValues}
        textValues={{
          instructions: [
            "Create a short name for the requirement, plus an abbreviation of no more than 5 characters.",
            <br />,
            <br />,
            "Attach any files the user will need to complete this requirement. Files can include documents, images and small videos a user can download.",
          ],
        }}
        isNew={isNew}
        onSave={onSave}
        showWaiver={showWaiver}
        showWeeksBeforePlacement={showWeeksBeforePlacement}
        showBusinessUnitsInsteadOfFacilities={showBusinessUnitsInsteadOfFacilities}
      />

      {isLoading && (
        <div className={styles.loading}>
          <Loading />
        </div>
      )}
    </div>
  );
}
