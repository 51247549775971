import { Auth } from "auth";
import environment from "environment";
import { createContainer } from "unstated-next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { makeAuthedMultipartRequest, makeAuthedRequest } from "utils/makeAuthedRequest";
import { useState } from "react";

export function useAdmin() {

  const queryClient = useQueryClient();
  const takeQuiz = 
    async (id) =>
      await makeAuthedRequest(`/cpnw/TakeQuiz?id=${id}`, {
        method: "GET",
      }).then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const body = await res.json();
          throw new Error(body.message);
        }
      })

    const { data: adminUsersData, isLoading: adminUsersDataLoading, refetch: refetchAdminUsers } = useQuery(
      ["AdminUsersGet"],
      async () =>
        await makeAuthedRequest(`/cpnw/Users`, {
          method: "GET",
        }).then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const body = await res.json();
            throw new Error(body.message);
          }
        }),
        {
          enabled: false
        }
    );

    const updateUsersActive = async (data) => {
      const res = await makeAuthedRequest(`/cpnw/users/UpdateActive`, {
        method: "POST",
        body: JSON.stringify(data)
      });

      if (res.ok) {
        const result = await res.json();
        queryClient.invalidateQueries('AdminUsersGet');
        return result; 
      } else {
        const body = await res.json();
        throw new Error(body.message);
      }
    };

    const updateUsersInactive = async (data) => {
      const res = await makeAuthedRequest(`/cpnw/users/UpdateInactive`, {
        method: "POST",
        body: JSON.stringify(data)
      });

      if (res.ok) {
        const result = await res.json();
        queryClient.invalidateQueries('AdminUsersGet');
        return result; 
      } else {
        const body = await res.json();
        throw new Error(body.message);
      }
    };

    const updateUserAccountRequest = async (data) => {
      const res = await makeAuthedRequest(`/cpnw/users/UpdateAccountRequests`, {
        method: "POST",
        body: JSON.stringify(data)
      });

      if (res.ok) {
        const result = await res.json();
        queryClient.invalidateQueries('AdminUsersGet');
        return result; 
      } else {
        const body = await res.json();
        throw new Error(body.message);
      }
    };

    const updateUserReturned = async (data) => {
      const res = await makeAuthedRequest(`/cpnw/users/UpdateReturned`, {
        method: "POST",
        body: JSON.stringify(data)
      });

      if (res.ok) {
        const result = await res.json();
        queryClient.invalidateQueries('AdminUsersGet');
        return result; 
      } else {
        const body = await res.json();
        throw new Error(body.message);
      }
    };

    const { data: documentsData, refetch: refetchDocuments } = useQuery(
      ["DocumentsGet"],
      async () =>
        await makeAuthedRequest(`/cpnw/Org/Documents`, {
          method: "GET",
        }).then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const body = await res.json();
            throw new Error(body.message);
          }
        }),
        {
          enabled: false, // Prevent automatic fetching
        }
    );

    const { data: assignmentsData, refetch: refetchAssignments,  } = useQuery(
      ["AssignmentsGet"],
      async () => {
        const res = await makeAuthedRequest(`/cpnw/Assignments`, {
          method: "GET",
        });
        
        if (res.ok) {
          return res.json();
        } else {
          const body = await res.json();
          throw new Error(body.message);
        }
      },
      {
        enabled: false
      }
    );

    const addOrUpdateAssignment = async (data) => {
      const res = await makeAuthedRequest(`/cpnw/AddAssignment`, {
        method: "POST",
        body: JSON.stringify(data)
      });

      if (res.ok) {
        const result = await res.json();
        //queryClient.invalidateQueries('AssignmentsGet');
        return result; 
      } else {
        const body = await res.json();
        throw new Error(body.message);
      }
    };


   const deleteAssignment = async (cid, aid, edbuid) => {
    const res = await makeAuthedRequest(`/cpnw/DeleteAssignment?aid=${aid}&edbuid=${edbuid}&cid=${cid}`, {
      method: "DELETE"
    });

    if (res.ok) {
      const result = await res.json();
      //queryClient.invalidateQueries('AssignmentsGet');
      return result; 
    } else {
      const body = await res.json();
      throw new Error(body.message);
    }
  };

  const affiliationRequestPost = async (data) => {
    const res = await makeAuthedRequest(`/cpnw/RequestAffiliation`, {
      method: "POST",
      body: JSON.stringify(data)
    });

    if (res.ok) {
      const result = await res.json();
      return result;
    } else {
      const body = await res.json();
      throw new Error(body.message);
    }
  }

  return {
    adminUsersData,
    adminUsersDataLoading,
    refetchAdminUsers,

    updateUsersActive,
    updateUsersInactive,
    updateUserAccountRequest,
    updateUserReturned,

    documentsData,
    refetchDocuments,

    assignmentsData,
    refetchAssignments,
    
    addOrUpdateAssignment,
    deleteAssignment,

    affiliationRequestPost,
  };
}
export const AdminContainer = createContainer(useAdmin);
